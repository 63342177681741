.card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.3s ease;

  /* Contoh margin untuk menjaga jarak antar card */
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.card-description {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

.details-button,
.delete-button {
  background-color: #348f7d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-button:hover {
  background-color: #2b7464;
}

.delete-button {
  background-color: #e74c3c;
}

.delete-button:hover {
  background-color: #c0392b;
}

.delete-button-edit {
  background-color: #e74c3c;
  /* Warna merah untuk mode edit */
}

.delete-button-edit:hover {
  background-color: #c0392b;
  /* Hover warna merah untuk mode edit */
}