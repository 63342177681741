.add-device-page {
    background-color: #E0F7FA;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-device-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-device-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.add-device-title {
    font-size: 24px;
    font-weight: bold;
    color: #348F7D;
    text-align: center;
}

.add-device-underline {
    width: 30%;
    height: 3px;
    background-color: #348F7D;
    margin-top: 5px;
}

.add-device-paper {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-device-id {
    font-size: 18px;
    font-weight: bold;
    color: #348F7D;
    align-self: flex-start;
    margin-bottom: 10px;
}

.add-device-input {
    margin-bottom: 20px;
}

.add-device-button {
    background-color: #348F7D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.add-device-button:hover {
    background-color: #2b7464;
}

.add-device-message {
    color: #D32F2F;
    margin-top: 10px;
}

@media (max-width: 700px) {
    .add-device-container {
        width: 100%;
    }

    .add-device-paper { 
        padding: 15px;
        width: 90%;
        margin-top: 10%;
    }

    .add-device-title {
        margin-top: 10%;
        font-size: 25px;
    }

    .add-device-underline {
        width: 70%;
    }

    .add-device-button {
        width: 100%;
    }
}