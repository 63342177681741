.tambah-kolam-page {
    background-color: #E0F7FA;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.tambah-kolam-container {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tambah-kolam-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
}

.tambah-kolam-title {
    font-size: 24px;
    font-weight: bold;
    color: #348F7D;
}

.tambah-kolam-underline {
    width: 30%;
    height: 3px;
    background-color: #348F7D;
    margin-top: 5px;
}

.tambah-kolam-paper {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tambah-kolam-name {
    font-size: 18px;
    font-weight: bold;
    color: #348F7D;
    align-self: flex-start;
    margin-bottom: 10px;
}

.tambah-kolam-input {
    margin-bottom: 20px;
}

.tambah-kolam-button {
    background-color: #348F7D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    text-align: center;
}

.tambah-kolam-button:hover {
    background-color: #2b7464;
}

.tambah-kolam-message {
    color: #D32F2F;
    margin-top: 10px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {

    .tambah-kolam-container {
        max-width: 100%;
    }

    .tambah-kolam-paper {
        width: 90%;
        margin-top: 10%;
    }

    .tambah-kolam-title {
        margin-top: 10%;
        font-size: 25px;
    }

    .tambah-kolam-name {
        font-size: 16px;
    }

    .tambah-kolam-input {
        font-size: 14px;
    }

    .tambah-kolam-button {
        padding: 8px 16px;
    }
}