/* Existing CSS */
.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    width: 90%;
    max-width: 600px;
    background: #ACE9E6;
    padding-bottom: 30px;
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.header-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    color: #01A5A0;
}

.text {
    color: #01A5A0;
    font-size: 6vw;
    font-weight: 700;
}

.underline {
    width: 61px;
    height: 6px;
    background: #01A5A0;
    border-radius: 9px;
}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%;
    max-width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
}

.input img {
    margin: 0px 15px;
    width: 24px;
    /* Adjusted icon size */
    height: 24px;
    /* Adjusted icon size */
}

.input input {
    height: 50px;
    width: calc(100% - 60px);
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 16px;
}

.dropdown .gender-container {
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%;
    max-width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 0 15px;
}

.dropdown .gender-container .MuiFormControl-root {
    width: 100%;
}

.dropdown .gender-container .MuiInputBase-root {
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 16px;
}

.dropdown .gender-container .MuiInputLabel-root {
    color: #797979;
}

.dropdown .gender-container .MuiSelect-root {
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 16px;
}

.forgot-password {
    padding-left: 10%;
    margin-top: 30px;
    color: #797979;
    font-size: 16px;
}

.forgot-password span {
    color: #4c00b4;
    cursor: pointer;
}

.submit-container {
    display: flex;
    gap: 30px;
    margin: 30px auto;
    flex-direction: row;
    align-items: center;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    max-width: 400px;
    height: 59px;
    color: #fff;
    background: #01A5A0;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}

.gray {
    background: #eaeaea;
    color: #676767;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Media Queries */

@media (max-width: 768px) {

    .container {
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 20%;
        max-width: 85%;
        background: #ACE9E6;
        padding-bottom: 30px;
        box-sizing: border-box;
    }

    .submit-container {
        flex-direction: row;
        gap: 25px;
    }

    .submit {
        width:130px;
        height: 50px;
    }

    .inputs {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .input {
        height: 50px;
        max-width: 90%;
    }

    .forgot-password{
        margin-top: 20px;
        padding-left: 5%;
    }
}