.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 50px;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 80px;
}

.home-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 10px;
}

.home-title {
    color: #01A5A0;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.home-underline {
    width: 61px;
    height: 3px;
    background: #01A5A0;
    border-radius: 3px;
}

.card-container {
    width: 100%;
    max-width: 800px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-device-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 80px;
}

.add-device-button {
    background-color: #348F7D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.add-device-button:hover {
    background-color: #2b7464;
}

/* Media Query untuk Responsivitas */
@media (max-width: 768px) {
    .home-container {
        min-width: 75%;
        padding: 0 10px;
    }

    .home-title {
        font-size: 30px;
    }

    .home-underline {
        width: 50px;
    }

    .card-container {
        padding: 15px;
        margin-top: 15px;
    }

    .add-device-button {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
}