.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 0 20px;
}

.settings-paper {
    padding: 20px;
    width: 100%;
}

.settings-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.settings-title {
    color: #01A5A0;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.settings-underline {
    width: 60px;
    height: 4px;
    background: #01A5A0;
    border-radius: 3px;
}

.settings-info {
    font-size: 18px;
    margin: 10px 0;
}

.logout-button {
    margin-top: 20px;
    width: 100%;
}

/* Media Query untuk Responsivitas */
@media (max-width: 768px) {
    .settings-paper {
        margin-top: 20px;
        max-width: 90%;
    }

    .settings-container {
        padding: 0 10px;
    }

    .settings-title {
        font-size: 30px;
    }

    .logout-button {
        width: 100%;
    }
}