.back-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;

}

@media (max-width: 600px) {
    .back-button {
        top: 10px;
        left: 10px;
    }
}