.kolam-ikan-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 50px;
}

.kolam-ikan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 80px;
}

.kolam-ikan-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-top: 10px;
}

.kolam-ikan-title {
  font-size: 24px;
  font-weight: bold;
  color: #01A5A0;
  text-align: center;
  margin-bottom: 10px;
}

.kolam-ikan-underline {
  width: 50px;
  height: 3px;
  background: #01A5A0;
  border-radius: 3px;
  margin-bottom: 20px;
}

.card-container {
    width: 100%;
    max-width: 800px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-device-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 80px;
}

.add-device-button {
  background-color: #348F7D;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.add-device-button:hover {
  background-color: #2b7464;
}

.edit-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.edit-button {
  background-color: #348F7D;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.edit-button:hover {
  background-color: #2b7464;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-button:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .kolam-ikan-container {
    padding: 0 10px;
    width: 75%;
  }

  .kolam-ikan-title {
    font-size: 30px;
  }

  .kolam-ikan-underline {
    width: 40px;
  }
  .card-container {
    padding: 15px;
    margin-top: 15px;
  }
  .add-device-button {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}
