.bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #348F7D !important;
}


@media (max-width: 600px) {
    .MuiBottomNavigationAction-label {
        font-size: 12px;

    }
}