.forgot-password-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-top: 50px;
    position: relative;
}

.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin-bottom: 80px;
    width: 100%;
    max-width: 800px;
}

.forgot-password-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 10px;
    position: relative;
}

.forgot-password-title {
    color: #01A5A0;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.forgot-password-underline {
    width: 61px;
    height: 3px;
    background: #01A5A0;
    border-radius: 3px;
}

.forgot-password-paper {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.forgot-password-info {
    margin-bottom: 20px;
    text-align: center;
}

.email-input {
    margin-bottom: 20px;
}

.reset-button {
    background-color: #348F7D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.reset-button:hover {
    background-color: #2b7464;
}

.forgot-password-message {
    margin-top: 20px;
    color: green;
    text-align: center;
}

.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.back-button svg {
    fill: #01A5A0;
    width: 24px;
    height: 24px;
}

/* Media Query untuk Responsivitas */
@media (max-width: 768px) {
    
    .forgot-password-title {
        font-size: 20px;
    }

    .forgot-password-underline {
        width: 50px;
    }

    .forgot-password-paper {
        width: 90%;
    }

    .reset-button {
        width: 100%;
        text-align: left;
        padding: 10px;
    }

    .back-button {
        top: 5px;
        left: 5px;
    }

    .back-button svg {
        width: 20px;
        height: 20px;
    }
}